<template>
  <div class="modal">
    <div class="modal__backdrop" @click="dismiss" />
    <div class="modal__window" :class="modalWindowClasses">
      <div v-if="title" class="modal__header">
        <slot name="header">
          <component :is="iconClose" class="modal__ic-close" @click="dismiss" width="20" />
          <div class="font-bold text-center">
            {{ title }}
          </div>
        </slot>
      </div>

      <main class="modal__body">
        <slot />
      </main>

      <div v-if="$slots.footer" class="modal__footer">
        <slot name="footer" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
import { IcClose } from '~/components/common/icons'

defineProps({
  title: String,
  iconClose: {
    type: Object,
    default: IcClose,
  },
  modalWindowClasses: String,
})
const emit = defineEmits(['dismiss'])

const dismiss = () => {
  emit('dismiss')
}
</script>

<style scoped>
.modal {
  @apply flex fixed top-0 left-0 w-full h-full z-50 overflow-y-hidden overflow-auto;
}
.modal__backdrop {
  @apply absolute inset-0 z-10 bg-black opacity-50;
}
.modal__window {
  @apply relative z-20 flex flex-col bg-white w-full h-full mx-auto;
}
.modal__ic-close {
  @apply absolute left-4 top-3 cursor-pointer text-rs-primary;
}
.modal__body {
  @apply flex-1 overflow-hidden overflow-y-auto p-4;
}
.modal__footer,
.modal__header  {
  @apply relative flex justify-center items-center p-4;
}
.modal__header {
  @apply border-b-2 border-gray-300;
}
.modal__footer {
  @apply border-t-2 border-gray-300 pb-4;
}
@screen md {
  .modal {
    @apply pt-20;
  }
  .modal__window {
    @apply rounded-lg w-full h-auto mx-auto min-h-[200px] max-w-[600px];
  }
  .modal__body {
    @apply py-6 px-8 max-h-[450px];
  }
}
</style>
